@font-face {
  font-family: 'Nunito-Regular';
  src: local('Nunito-Regular'),
    url('./assets/fonts/nunito/Nunito-Regular.ttf') format('woff'),
    url('./assets/fonts/nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: local('Nunito-SemiBold'),
    url('./assets/fonts/nunito/Nunito-SemiBold.ttf') format('woff'),
    url('./assets/fonts/nunito/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: local('Nunito-Bold'),
    url('./assets/fonts/nunito/Nunito-Bold.ttf') format('woff'),
    url('./assets/fonts/nunito/Nunito-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'Nunito-ExtraBold';
  src: local('Nunito-ExtraBold'),
    url('./assets/fonts/nunito/Nunito-ExtraBold.ttf') format('woff'),
    url('./assets/fonts/nunito/Nunito-ExtraBold.ttf') format('truetype');

}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url('./assets/fonts/roboto/Roboto-Regular.ttf') format('woff'),
    url('./assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

body {
  background-color: #F4F4F4 !important;
}

.content {
  margin-right: auto;
  margin-left: auto;
  background-color: #FFFFFF;
}


@media screen and (min-width: 600px) {
  .content {
    width: 1440px;
  }
}

/*  =====  RESPONSIVE - 1279px > 769px - Window LARGE =====  */

@media screen and (max-width:769px) and (min-width:600px) {
  .toolsContent {
    padding: 130px 0 130px 0 !important;
  }
}


@media screen and (max-width: 512px){
  .contentClients{
    margin-top: 80px;
  } 

  .text-reviews{
    display: none!important;
  }

  .imagesClients img {
    width: 100%;
    max-height: 40px;
    line-height: 48px;
    vertical-align: middle;
    transition: all 160ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}


/*  =====  RESPONSIVE - 599px > 360px - Mobile BIG =====  */

@media screen and (max-width:1000px) {
  .content {
    width: 100% !important;
  }

  .rowHeaderText .title {
    font-size: 30px;
    line-height: 32px;
  }

  .rowHeaderText .title2 {
    margin-top: 0px;
    font-size: 29px;
    line-height: 32px;
  }

  .rowHeaderText .subtitle {
    margin-top: 44px;
    font-size: 18px;
    color: #343748;
    letter-spacing: 0.5px;
    font-family: 'Roboto-Regular';
  }

  .titleClients {
    padding-bottom: 25px!important;
    font-family: 'Nunito-Bold';
    letter-spacing: 1.5px;
    line-height: 38px;
    font-size: 28px;
    color: #6523DF;
  }
  
  .contentClients {
    padding-bottom: 0px;
    /* margin-top: 10%; */
  }


  .clientSectionMobile {
    display: flex !important;
    overflow: auto;
    white-space: nowrap;
    margin: 0;
    justify-content: initial !important;
  }

  .imagesClients img {
    width: auto !important;
  }

  header {
    background-image: none !important;
    min-width: 400px;
    /* height: 460px !important; */
  }

  .toolsText {
    width: 100% !important;
  }
  
  .container .sectionFormContact {
    width: 100%!important;
  }
  

  .sectionFormContact .input-group {
    width: 100% !important;
  }

  .toolsContent {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
    background: transparent !important;
  }

  .formTitle {
    padding-bottom: 16px;
  }

  .horizontal {
    display: flex !important;
    overflow: auto;
    white-space: nowrap;
    margin: 0;
    justify-content: initial !important;
  }

  .imageProcess {
    width: auto !important;
  }

}