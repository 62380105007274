.process-container {
    margin-top: 0px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-radius: 2%;
    background-color: #541bbe;
}

.titleProcess {
    padding-bottom: 5px !important;
    font-family: 'Nunito-Bold';
    letter-spacing: 1.5px;
    line-height: 38px;
    font-size: 28px;
    padding-top: 20px;
    color: #f2eff7; /* Purple theme */
}

.process-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1200px;
}

.process-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
}

.icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid hsl(274, 85%, 68%); /* Purple border */
    margin-bottom: 10px;
}

.process-icon {
    font-size: 40px;
    color: hsl(276, 7%, 86%); /* Purple theme */
}

.process-label {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    .process-row {
        flex-direction: row; /* Change to horizontal layout on mobile */
        flex-wrap: nowrap; /* Prevent wrapping */
        overflow-x: auto; /* Add horizontal scrolling if needed */
        justify-content: flex-start; /* Align items to the start */
        padding: 10px;
        gap: 20px; /* Space between icons */
    }

    .process-step {
        margin: 0 10px; /* Adjust horizontal margin */
        flex: 0 0 auto; /* Ensure each step maintains its size */
    }

    .icon-circle {
        width: 60px;
        height: 60px;
    }

    .process-icon {
        font-size: 30px;
    }

    .process-label {
        font-size: 14px;
    }
}