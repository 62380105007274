.toolsContent {
    width: 100%;
    background: transparent url("../../assets/img/tecnologias2.png") no-repeat center;
    background-size: 86%;
    background-position: center center;
    padding: 230px 0 230px 0;
}

.herramientasTitle {
    font-family: 'Nunito-Bold';
    font-size: 28px;
    color: #6523DF;
    letter-spacing: 1.5px;
    line-height: 38px;
}

.toolsText {
    width: 377px;
    margin: 0 auto;
    font-family: 'Roboto-Regular';
    letter-spacing: 0.96px;
    font-size: 18px;
    line-height: 21px;
    color: #343748;
}