.services-container {
    margin-top: 60px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-radius: 1%;
    background-color: hsl(250, 100%, 99%);
    padding: 40px 20px;
}

.titleServices {
    font-family: 'Nunito-Bold';
    letter-spacing: 1.5px;
    line-height: 38px;
    font-size: 28px;
    color: #6735bf;
    margin-bottom: 50px;
}

.sectionServices {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 40px;
    padding: 20px;
}

.cardServices {
    border: none;
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow */
    background-color: #fff; /* White card background */
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    min-height: 400px;
}

.cardServices:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
}

.cardImage {
    width: 80px;
    height: 80px;
    margin: 20px auto;
}

.cardTitle {
    text-align: center;
    letter-spacing: 0;
    font-size: 18px;
    color: #541bbe;
    font-family: 'Nunito-Bold';
    text-transform: uppercase;
    margin: 10px 0;
}

.cardText {
    padding: 0 20px 20px;
    text-align: center;
    line-height: 1.6;
    letter-spacing: 0;
    font-size: 16px;
    color: #343748;
    font-family: 'Roboto-Regular';
}

@media (max-width: 768px) {
    .sectionServices {
        grid-template-columns: 1fr; /* Single column for small screens */
    }

    .cardImage {
        width: 60px;
        height: 60px;
    }

    .cardText {
        font-size: 14px;
    }

    .cardServices {
        min-height: 200px;
    }
}