header {
    background-image: url('../../assets/img/banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: right;
    height: 672px;
}

header img {
    width: 200px;
}

.rowHeaderText {
    padding: 4% 4%;
}

.title {
    font-family: 'Nunito-ExtraBold';
    letter-spacing: 2.5px;
    line-height: 50px;
    padding-top: 5%;
    font-size: 45px;
    max-width: 651px;
}

.title2 {
    font-family: 'Nunito-ExtraBold';
    letter-spacing: 2.5px;
    line-height: 50px;
    padding-top: 1%;
    font-size: 45px;
    max-width: 651px;
    color:#9400ca;
    ;
}

.subtitle {
    margin-top: 40px;
    font-size: 18px;
    color: #343748;
    letter-spacing: 0.5px;
    font-family: 'Roboto-Regular';
    max-width: 651px;
}

.btn-outline-purple {
    color: #522dce;
    border: 1px solid #120091;
    border-radius: 6px;
}

.btn-outline-purple:hover {
    color: #fff;
    background-color: #522dce;
    border-color: #522dce;
}

.button {
    width: 192px;
    height: 47px;
    font-size: 18px;
    box-shadow: 0 0 4px 1px #B1AEDE;
    margin-top: 24px;
    letter-spacing: 0.3px;
    font-family: 'Nunito-Bold';
    background: transparent;
}

.headerImage {
    width: 100%;
}

.customer-reviews {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Distribute space between the elements */
    background-color: #541bbe; /* Your background color */
    color: #fff; /* Your text color */
    padding: 10px;
    border-radius: 10px; /* Round the corners of the customer reviews section */
    margin-top: 20px; /* Add space above the customer reviews section */
    max-width: 451px;
    margin-bottom: 16px;
    margin-top: 39px;
  }
  
  .customer-images {
    display: flex; /* Ensure this is a flex container to handle the images in a row */
  }
  
  .customer-images img {
    border-radius: 50%;
    border: 2px solid #fff; /* Add border to separate overlapping images */
    margin-right: -15px; /* Adjust as needed to create the overlapping effect */
    width: 50px; /* Define a size for the images */
    height: 50px; /* Define a size for the images */
  }
  
  .rating {
    display: flex;
    align-items: center; /* Center the stars and text vertically */
    gap: 5px; /* Add a gap between the stars and the text */
  }
  
  .stars {
    margin-left: 20px;
    color: #ff0; /* Star color */
  }
  
  .text {
    display: flex; /* Make the text and the heart icon align horizontally */
    align-items: center; /* Align the text and icon vertically */
    gap: 5px; /* Add a gap between the text and the heart icon */
  }
  
  .heart {
    color: #a33; /* Heart color or use an actual heart icon */
  }

  .special-p {
    font-size: 0.9rem;
    font-style: italic;
  }