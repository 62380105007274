.form-container {
    margin-top: 0px;
    font-family: 'Arial', sans-serif;
    border-radius: 1%;
    background-color: #ebe9ed;
    padding-bottom: 90px;
}

.sectionFormContact {
    margin: 0 auto;
    padding-top: 5px;
    width: 70%!important;
    margin-bottom: 100px;
}

.formTitle {
    margin-top: 100px;
    color: #343748;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    font-family: 'Nunito-SemiBold';
    margin-bottom: 50px;
}

.input-group {
    width: 50% !important;
    margin: 0 auto;
}

.inputContact {
    height: 48px !important;
    border-radius: 9px 9px 9px 9px !important;
    background-color: #FFFFFF !important;
    box-shadow: 2px 2px 5px 0 #C0C0D6;
}

.inputContact::placeholder {
    font-size: 16px;
    color: #C0C0D6;
    letter-spacing: 0;
    line-height: 19px;
    font-family: 'Roboto-Regular';
}

.btn-primary-purpple {
    height: 48px;
    width: 146px;
    border-radius: 8px 8px 8px 8px !important;
    background: linear-gradient(0deg, #6523DF 0%, #120091 100%);
    box-shadow: 2px 2px 5px 0 #B1AEDE;
    color: #FFF !important;
    font-family: 'Nunito-Bold';
    font-size: 18px;
    letter-spacing: 0;
    text-transform: uppercase;
    float: right;
}

.phone-input-group {
    display: flex;
    width: 100%;
}

.countryCodeSelect {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.phoneInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.form-group-div {
    width: 100% !important;

}