.contentClients {
    top: 20px;
    padding-bottom: 0px;
}

.clientSectionMobile {
    width: 100%;
    display: flex;
    justify-content: center;
}

.titleClients {
    padding-bottom: 40px;
    font-family: 'Nunito-Bold';
    letter-spacing: 1.5px;
    line-height: 38px;
    font-size: 28px;
    color: #6523DF;
}

.imagesClients img {
    width: 100%;
    max-height: 57px;
    line-height: 48px;
    vertical-align: middle;
    transition: all 160ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.mt-16 {
    margin-top: 24px;
}


@media screen and (min-width: 1200px) {
 .image-clients-big {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
 }
}