.footer {
    background-color: #000; /* Dark background */
    color: #aaa; /* Light text */
    padding: 50px 20px;
    font-family: 'Nunito-Regular';
    margin-top: 90px;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section {
    flex: 1 1 200px;
    margin: 20px;
}

.footer-section h3 {
    font-size: 16px;
    color: #fff; /* White section headers */
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: 'Nunito-Bold';
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #aaa;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #f2f2f2; /* Light hover color */
}

.footer-bottom {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
    color: #555; /* Subtle footer text */
}

.social-icons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

.social-icons a {
    color: #aaa;
    font-size: 20px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #f2f2f2; /* Light hover color */
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
        margin: 10px 0;
    }
}